#machineRentalTable {
  height: 100%;
  position: relative
}

.ag-row-last {
  border-bottom: unset!important;
}

.dialog-paper {
  min-width: 40%;
}